import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkeleton = () => {
	return (
		<div className="skeleton_card">
			<div className="card_img">
				<Skeleton height={270} />
			</div>

			<div className="card_body_skeleton">
				<div className="card_content_skeleton">
					<Skeleton width={"100%"} height={25} />
					<Skeleton width={"75%"} height={20} />
				</div>
				<div className="skeleton_card_btn">
					<Skeleton width={"50%"} height={20} />
				</div>
			</div>
		</div>
	);
};

export default CardSkeleton;
