import React from "react";
import "./index.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const CarDetailSkeleton = () => {
  return (
    <>
      <div className="nft_detail_container_skeleton">
        <div className="nft_details_skeleton">
          <div className="img_container_skeleton">
            <Skeleton  className="img_section" />
          </div>
          <div className="content_container_skeleton">
            <div className="header_skeleton">
              <Skeleton className="header_top_skeleton" />
              <Skeleton className="header_top_skeleton"/>
            </div>
            <div className="header_skeleton_title">
              <Skeleton className="title_skeleton" />
              <Skeleton className="subtitle_skeleton" />
            </div>
          
              <Skeleton  className="create_section_skeleton" />
            
            <div className="header_skeleton_trait">
              <Skeleton className="trait_box" />
              <Skeleton className="trait_box" />
             
            </div>
            <div className="header_skeleton_trait">
              <Skeleton className="trait_box" />
              <Skeleton className="trait_box" />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarDetailSkeleton;
