import React, { useContext, useEffect, useState } from "react";
import { Image } from "../../assests/images";
import "./index.css";
import { Link } from "react-router-dom";
import CustomeCheckBox from "../custome_checkbox";
import { Context } from "../../context/Context";
import { pointContext } from "../../context/dappContext";
const StakingBox = ({ nft, onCheckboxChange, stakeData, isChecked }) => {
	console.log(stakeData);
	const { messageSign } = useContext(Context);
	const { bonusPoints} = useContext(pointContext);
	const isNftInStake =
		stakeData &&
		stakeData?.stake?.some(
			(stake) => stake.nft_id === Number(nft.id) && stake.isStaked
		);

	const [loading, setLoading] = useState(false);
	const [remainingTime, setRemainingTime] = useState(0);
	const [claimMessage, setClaimMessage] = useState("");
	const claimPoints = {
		nft_id: parseInt(nft?.id),
	};

	const handleClaimBonus = async () => {
		try {
			setLoading(true);
			const signMessageData = await messageSign(
				"Sign this message to Claim Bonus Points"
			);
			if (signMessageData) {
				await bonusPoints(claimPoints, signMessageData);
				
				window.location.reload();
			}
		} catch (error) {
			// Handle error if needed
			console.error("Error claiming bonus points", error);
		} finally {
			setLoading(false);
		}
	};
	const claimData =
		stakeData &&
		stakeData?.stake?.find(
			(stake) => stake.nft_id === Number(nft.id) && stake.isStaked
		);

	useEffect(() => {
		console.log("claim Data", claimData);
		if (claimData && claimData?.remainingTimeSeconds) {
			setRemainingTime(claimData.remainingTimeSeconds);

			const timerInterval = setInterval(() => {
				setRemainingTime((prevTime) => {
					if (prevTime > 0) {
						return prevTime - 1;
					} else {
						clearInterval(timerInterval); // Stop the timer when time reaches 0
						return 0;
					}
				});
			}, 1000);

			return () => clearInterval(timerInterval);
		}
	}, [claimData]);
	return (
		<>
			<div className="card">
				<div className="top_bar">
					{!isNftInStake && !nft.listed && (
						<CustomeCheckBox onChange={onCheckboxChange} checked={isChecked} />
					)}

					{isNftInStake && (
						<div className="staking_days">
							<span>
								<svg
									width="14"
									height="12"
									viewBox="0 0 14 12"
									fill="none"
									className="icon"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.2127 4.38488H12.7873M5.78162 1.03801L4.56324 4.38488L7 10.7997L9.43676 4.38488L8.21838 1.03801M7.37439 10.6672L12.8319 4.67056C12.9244 4.56893 12.9706 4.51812 12.9883 4.46139C13.0039 4.41138 13.0039 4.35838 12.9883 4.30837C12.9706 4.25164 12.9244 4.20083 12.8319 4.0992L10.1921 1.19858C10.1384 1.13956 10.1115 1.11005 10.0786 1.08883C10.0494 1.07004 10.0169 1.05608 9.98242 1.04759C9.94354 1.03801 9.90159 1.03801 9.81769 1.03801H4.18231C4.09841 1.03801 4.05646 1.03801 4.01758 1.04759C3.98314 1.05608 3.95058 1.07004 3.92141 1.08883C3.88849 1.11005 3.86163 1.13956 3.80792 1.19858L1.1681 4.0992C1.0756 4.20083 1.02936 4.25164 1.01169 4.30837C0.996105 4.35838 0.996105 4.41138 1.01169 4.46139C1.02936 4.51812 1.0756 4.56893 1.1681 4.67056L6.62561 10.6672C6.75418 10.8085 6.81847 10.8791 6.89543 10.9049C6.96296 10.9275 7.03704 10.9275 7.10457 10.9049C7.18153 10.8791 7.24582 10.8085 7.37439 10.6672Z"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</span>
							<span className="text">+1440/day</span>
						</div>
					)}
				</div>
				<Link to={`/nft-detail/${nft.id}`}>
					<img src={nft.image} alt="nft" className="card_img" />
				</Link>
				<div className="card_body">
					<div className="card_content">
						<h4>{nft.name}</h4>
						<span>#{Number(nft.id)}</span>
					</div>
					{nft.listed ? (
						<button className="card_btn white">listed</button>
					) : isNftInStake ? (
						<button className="card_btn yellow">staked</button>
					) : (
						""
					)}

					{claimData && claimData?.isClaimable ? (
						<div className="stake_status white" onClick={handleClaimBonus}>
							<span className="text">
								{loading ? "Claiming..." : "claim points"}
							</span>
							<span className="icon white">
								<img
									src={Image.diamond_icon}
									alt="diamond_icon"
									className="diamond_icon_bonus"
								/>
								{claimData?.claimPoints}
							</span>
						</div>
					) : (
						<div className="stake_status white disable">
							<span className="text">
								{claimData?.remainingDays
									? `claim in ${claimData?.remainingDays} days`
									: "Stake"}
							</span>
							<span className="icon white">
								<img
									src={Image.diamond_icon}
									alt="diamond_icon"
									className="diamond_icon"
								/>

								{claimData && claimData?.claimPoints
									? claimData?.claimPoints
									: null}
							</span>
						</div>
					)}

					{/* disable stake status */}
				</div>
			</div>
		</>
	);
};

export default StakingBox;
