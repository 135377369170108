import React, { useContext, useState, useEffect } from "react";
import "./index.css";
import PageHeader from "../../component/PagerHeader";
import Card from "../../component/card";
import Alert from "../../component/alert/Alert";
import { Context } from "../../context/Context";
import CardSkeleton from "../../component/cardSkeleton";
import { pointContext } from "../../context/dappContext";
import {
	CONNECT_WALLET_MESSAGE,
	HOME_PAGE_MESSAGE,
} from "../../constant/constant";
const Home = () => {
	const { isConnected, getNFTsDetails, userNFTs, setMessage, setIsError } =
		useContext(Context);
	const [nfts, setNfts] = useState({});

	const { profileData, loading, setLoading, allPoints, fetchProfileData } =
		useContext(pointContext);
	useEffect(() => {
		async function getNFTs() {
			setLoading(true);
			const nftsData = await getNFTsDetails();
			// await allPoints();
			// await fetchProfileData();
			setNfts(nftsData);
			if (loading) setLoading(false);
		}

		if (isConnected) {
			if (Object.values(userNFTs).length > 0) {
				setNfts(userNFTs);
				if (loading) setLoading(false);
			} else {
				getNFTs();
			}
		}
	}, [isConnected]);
	const skeletonCount = Math.floor(window.innerHeight / 100);
	return (
		<div className="my-nft_container">
			<PageHeader headerText={"my nfts"} />
			{/* <Alert message={`DESCRIPTION ABOUT TOKEN BOUND ACCOUNTS AND POINTS`}/> */}
			{!isConnected ? <Alert message={CONNECT_WALLET_MESSAGE} /> : null}
			{loading && isConnected && Object.values(nfts).length === 0 ? (
				<div className="card_container">
					{Array.from({ length: skeletonCount }).map((_, index) => (
						<CardSkeleton key={index} />
					))}
				</div>
			) : !loading && isConnected && Object.values(nfts).length === 0 ? (
				<Alert message={"No NFTs found in your wallet."} />
			) : (
				<>
					{isConnected && <Alert message={HOME_PAGE_MESSAGE} />}
					<div className="card_container">
						{isConnected &&
							Object.values(nfts).map((nft) => (
								<Card key={nft.id} nft={nft} stakeData={profileData.stake} />
							))}
					</div>
				</>
			)}
		</div>
	);
};

export default Home;
