import React from "react";
import "./index.css";
import { Image } from "../../assests/images";
import WalletAddress from "../walletAddress";
import Rank from "../Rank";
const Leaderboard = ({ data, index, isHighlighted }) => {
	return (
		<div className={`single_leaderboard ${isHighlighted ? "active" : ""}`}>
			<div className="position">
				<span>{index ? `#${index}` : ""}</span>
			</div>

			<div className="rank_container">
				{/* <Rank rank={data && data?.rank ? data?.rank : ""} /> */}
				<Rank rank={data?.points?.value ?? "0"} />
			</div>
			<div className="wallet_address">
				<WalletAddress
					walletAddress={data && data?.walletAddress ? data?.walletAddress : ""}
				/>
				{data?.total_nfts >= 7 && (
					<span className="wale_icon">
						<img src={Image.wale} alt="wale" />
					</span>
				)}
			</div>
			<div className="points">
				<span>{data?.points?.value ?? "0"}</span>
			</div>
		</div>
	);
};

export default Leaderboard;
