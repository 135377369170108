import React, { useContext, useState } from "react";
import "./index.css";
import { Context } from "../../context/Context";
import { pointContext } from "../../context/dappContext";

const Account_Tap = ({ matchedStake ,nft,id}) => {
  const {createTokenBoundAccount}=useContext(Context);
	const [account, setAccount] = useState(null);
	const { tokenBoundAccount, getProfileData } = useContext(pointContext);
  const address = matchedStake?.token_bound_account?.address;
  const isTokenBound = matchedStake?.token_bound_account?.bound_account;
  async function getAccount(id) {
    try {
      const _account = await createTokenBoundAccount(id);
      if (_account) {
        const boundAccount = {
          nft_id: Number(id),
          nft_name: nft?.name,
          bound_account_address: _account,
        };
        tokenBoundAccount(boundAccount);
        window.location.reload();
      }
      setAccount(_account);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
    {
      isTokenBound ? (
        <div className="account_Tap yellow">
          <span className="text">
            {address.slice(0, 6)}...{address.slice(-5, -1)}
          </span>
        </div>
      ) : (
        <div className="account_Tap white">
          <span className="text" onClick={() => (account ? null : getAccount(id))}>
            create account
          </span>
        </div>
      )
    }
    
    </>

    // <div className={`account_Tap ${isTokenBound ? "yellow" : "white"}`}>
    //   <span className="text" onClick={() => (account ? null : getAccount(id))}>
    //     {isTokenBound
    //       ? `${address.slice(0, 6)}...${address.slice(-5, -1)}`
    //       : "create account"}
    //   </span>
    // </div>
  );
};

export default Account_Tap;
