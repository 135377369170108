import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ContextProvider } from "./context/dappContext";
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from "react-loading-skeleton";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Router>
			{/* <ContextProvider> */}
			<SkeletonTheme baseColor="#202020" highlightColor="#444">
				<App />
				</SkeletonTheme>
			{/* </ContextProvider> */}
		</Router>
	</React.StrictMode>
);

reportWebVitals();
