import React from "react";
import { IoAlertOutline } from "react-icons/io5";
import "./index.css";
const Alert = ({ message }) => {
	return (
		<div>
			{message === "connect your wallet to view your nfts." ? (
				<div className="alert_container">
					<span className="alet-icon">
						<IoAlertOutline />
					</span>

					<span className="text">{message}</span>
				</div>
			) : (
				<div className="alert_container">
					<span className="alet-icon">
						<IoAlertOutline />
					</span>

					<span className="textlower">{message}</span>
				</div>
			)}
		</div>
	);
};

export default Alert;
