import React, { useContext, useState, useEffect } from "react";
import "./index.css";
import PageHeader from "../../component/PagerHeader";

import StakingBox from "../../component/staking-box/StakingBox";
import Alert from "../../component/alert/Alert";
import Popup from "../../component/Popup";
import { Context } from "../../context/Context";
import { pointContext } from "../../context/dappContext";
import CardSkeleton from "../../component/cardSkeleton";
import {
	CONNECT_WALLET_MESSAGE,
	STAKING_PAGE_MESSAGE,
} from "../../constant/constant";
const Staking = () => {
	const [popUpOpen, setPopUpOpen] = React.useState(false);
	const {
		isConnected,
		getNFTsDetails,
		showMessage,
		userNFTs,
		messageSign,
		totalSupply,
	} = useContext(Context);
	const [nfts, setNfts] = useState({});
	const [stakes, setStakes] = useState([]);
	const [isStaked, setIsStaked] = useState(false);
	const {
		AddStake,
		profileData,
		loading,
		setLoading,
		allStakedNft,
		fetchProfileData,
		allPoints,
		allStakedNftsData,
	} = useContext(pointContext);
	const [addingStake, setAddingStake] = React.useState(false);
	const [submittingStake, setSubmittingStake] = useState(false);
	const [stakingSuccess, setStakingSuccess] = useState(false);
	const [status, setStatus] = useState(null);

	useEffect(() => {
		async function getNFTs() {
			setLoading(true);
			const nftsData = await getNFTsDetails();

			// await allPoints();
			// await fetchProfileData();
			setNfts(nftsData);
			if (loading) setLoading(false);
		}

		if (isConnected) {
			if (Object.values(userNFTs).length > 0) {
				setNfts(userNFTs);
				if (loading) setLoading(false);
			} else {
				getNFTs();
			}
		}
	}, [isConnected]);

	useEffect(() => {
		setIsStaked(stakes.length > 0);
	}, [stakes]);

	const handleCheckboxChange = async (nft) => {
		try {
			const nftId = Number(nft.id);
			const nftName = nft.name;

			const isStaked = stakes.some(
				(stake) => stake.nft_id === nftId && stake.nft_name === nftName
			);

			if (isStaked) {
				const updatedStakes = stakes.filter(
					(stake) => stake.nft_id !== nftId || stake.nft_name !== nftName
				);
				setStakes(updatedStakes);
			} else {
				setStakes([...stakes, { nft_id: nftId, nft_name: nftName }]);
			}
		} catch (error) {
			console.error("Error handling checkbox change:", error);
		}
	};

	const percentage = isConnected
		? ((Number(allStakedNft.totalStakedNftsCount) / totalSupply) * 100).toFixed(
				1
		  )
		: 0;

	const handleStake = async () => {
		try {
			setStatus(null);
			setSubmittingStake(true);
			setAddingStake(true);
			setPopUpOpen(true);
			const signMessageData = await messageSign(
				"Sign this message to stake your NFTs"
			);
			if (Number(signMessageData.code) === 4001) {
				setStakingSuccess(false);
				setStatus(Number(signMessageData.code));
				setSubmittingStake(false);
				setPopUpOpen(false);
				setStakes([]);
				return;
			}
			if (stakes.length > 0 && Number(signMessageData.code) !== 4001) {
				if (signMessageData) {
					await AddStake(stakes, signMessageData);
					await fetchProfileData();
					await allPoints();
					await allStakedNftsData();
					// await fetchAllData()
					// window.location.reload();
					// await fetchNFTsAndProfileData();
					// SideMenu();

					showMessage("Staking successful");
					setStakingSuccess(true);
					setStatus(null);
				} else {
					showMessage("Please select at least one NFT");
				}
			}
		} catch (error) {
			console.error("Error adding stake:", error);
		} finally {
			setAddingStake(false);
			setSubmittingStake(false);
		}
	};
	const skeletonCount = Math.floor(window.innerHeight / 100);
	const handleClosePopup = () => {
		setPopUpOpen(false);
		setStakes([]);
	};
	return (
		<>
			<div className="my_staking_container">
				<PageHeader headerText={"staking"} />
				{/* <Alert message={`Soft-stake your NFTs to start earning points. No transactions required to stake, you’ll be asked to sign with your wallet. Listing your NFT will automatically un-stake your NFT.`}/> */}

				{!isConnected ? <Alert message={CONNECT_WALLET_MESSAGE} /> : null}

				{loading && isConnected && Object.values(nfts).length === 0 ? (
					<div className="staking_container">
						{Array.from({ length: skeletonCount }).map((_, index) => (
							<CardSkeleton key={index} />
						))}
					</div>
				) : !loading && isConnected && Object.values(nfts).length === 0 ? (
					<Alert message={"No NFTs found in your wallet."} />
				) : (
					<>
						{isConnected && <Alert message={STAKING_PAGE_MESSAGE} />}

						{isConnected && (
							<div className="staking_stats_container">
								<div className="stakes_stats">
									<div className="stats">
										<span className="points">
											{isConnected &&
												allStakedNft &&
												Number(allStakedNft.totalStakedNftsCount)}{" "}
											({percentage}%)
										</span>
										<span className="disc">Total Staked</span>
									</div>
									<div className="stats">
										<span className="points">
											{isConnected &&
												allStakedNft &&
												Number(allStakedNft.totalStakedNftsPoints)}
										</span>
										<span className="disc">Total Points generated</span>
									</div>
								</div>
								{!isStaked ? (
									<button
										className={`stake_btn`}
										// onClick={() => setPopUpOpen(!popUpOpen)}
									>
										{"Stake"}
									</button>
								) : (
									<button
										className={`stake_btn ${isStaked ? "white" : ""}`}
										// onClick={() => setPopUpOpen(!popUpOpen)}
										onClick={handleStake}
									>
										{addingStake ? "Staking..." : "Stake"}
									</button>
								)}
							</div>
						)}

						{isConnected && nfts && Object.values(nfts).length > 0 && (
							<>
								<div className="staking_container">
									{isConnected &&
										nfts &&
										Object.values(nfts).map((nft) => (
											<StakingBox
												key={nft.id}
												nft={nft}
												onCheckboxChange={() => handleCheckboxChange(nft)}
												stakeData={profileData}
												isChecked={stakes.some(
													(stake) =>
														stake.nft_id === Number(nft.id) &&
														stake.nft_name === nft.name
												)}
											/>
										))}
								</div>
							</>
						)}
					</>
				)}
			</div>
			{popUpOpen && (
				<Popup
					selectedNFTs={stakes}
					onClose={handleClosePopup}
					stakingInProgress={submittingStake}
					stakingSuccess={stakingSuccess}
					status={status}
				/>
			)}
		</>
	);
};

export default Staking;
