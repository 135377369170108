import React from "react";
import "./index.css";

const Rank = ({ rank }) => {
  
  let text, colorClass;

  // if (rank == 1 ) {
  //   text = "gold";
  //   colorClass = "gold";
  // } else if (rank ==2) {
  //   text = "silver";
  //   colorClass = "silver";
  // } else if (rank == 3 ) {
  //   text = "bronze";
  //   colorClass = "bronze";
  // } else {
  //   // Default values for ranks outside the specified ranges
  //   text = "";
  //   colorClass = "";
  // }
  if (rank >= 10000 && rank <= 49999) {
    text = "bronze";
    colorClass = "bronze";
  } else if (rank >= 50000 && rank <= 99999) {
    text = "silver";
    colorClass = "silver";
  } else if (rank > 99999) {
    text = "gold";
    colorClass = "gold";
  } else {
    // Default values for ranks outside the specified ranges
    text = "";
    colorClass = "";
  }

  return (
    <div className={`rank_box ${colorClass}`}>
      <span className={`text`}>{text}</span>
    </div>
  );
};

export default Rank;
