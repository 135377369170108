import React, { useContext, useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useConnect } from "wagmi";
import { TokenboundClient } from "@tokenbound/sdk";
import { NFTAbis, NFTAddress } from "./constants";
import { readContract, switchNetwork } from "@wagmi/core";
import { mainnet } from "viem/chains";
import { createWalletClient, http, custom } from "viem";
import axios from "axios";
import { pointContext } from "./dappContext";
import { signMessage } from "wagmi/actions";
import { useNetwork } from "wagmi";
export const Context = React.createContext();

export const ContextProvider = ({ children }) => {
	const { chain } = useNetwork();
	const { address, isConnected } = useAccount();
	const { open } = useWeb3Modal({
		key: address, // Use the wallet address as a key
	});
	const [showMessage, setShowMessage] = useState(false);
	const [message, setMessage] = useState("");
	const [isError, setIsError] = useState(false);
	const [userNFTs, setUserNFTs] = useState({});
	const [userPoints, setUserPoints] = useState(0);
	const [totalSupply, setTotalSupply] = useState(0);
	const {
		Logout,
		getProfileData,
		addTotalNfts,
		registerOrLoginUser,
		removeFromStake,
		setLoading,
		userLogin,
	} = useContext(pointContext);
	const [prevAddress, setPrevAddress] = useState(null); // Add a previous address state variable

	const connectWallet = async () => {
		try {
			await open();
		} catch (error) {
			console.log(error);
		}
	};
	const disconnectWallet = async () => {
		try {
			await open();
		} catch (error) {
			console.log(error);
		}
	};
	const connectAndLogin = async (user) => {
		try {
			// Call the registerOrLoginUser function
			const result = await registerOrLoginUser(user);

			if (result.success) {
				// Display a success message
				setMessage(result.message);
				setIsError(false);
			} else {
				// Display an error message
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error connecting and logging in:", error);
			// Display an error message
			setMessage("Error connecting and logging in");
			setIsError(true);
		}
	};
	const getPoints = async () => {
		try {
			const { data } = await getProfileData();
			setUserPoints(data?.points?.value);
		} catch (err) {
			console.log("err", err.response.data.message);
			setUserPoints(0);
		}
	};
	// const { connect } = useConnect({
	// 	onConnect: async () => console.log("This will run when the user connects"),
	// });
	useEffect(() => {
		if (isConnected) {
			if (!address) {
				connectWallet();
			}

			getTotalSupply();
		}
		if (userLogin) {
			getPoints();
		}
	}, [userLogin, isConnected, address]);

	useEffect(() => {
		async function networkSwitch() {
			console.log("called");
			await getTokenBoundAccount(44);
		}

		if (isConnected) {
			networkSwitch();
		}
	}, []);

	useEffect(() => {
		async function networkSwitch() {
			if (chain && chain.id !== 1) {
				const network = await switchNetwork({ chainId: 1 });
				window.location.reload();
			}
		}
		networkSwitch();
	}, [chain]);

	useEffect(() => {
		const switchAccount = async () => {
			// if (isConnected && address) {
			// 	// Disconnect the previous account

			// } else {
			// 	// Hide the message when the wallet is disconnected
			// 	setShowMessage(false);
			// }
			if (prevAddress && prevAddress !== address) {
				setUserNFTs({});
				await logoutPreviousAccount();
				setLoading(true);
				const user = {
					walletAddress: address,
				};
				await connectAndLogin(user);
				window.location.reload();
				setLoading(false);
			}
		};

		// Update the previous address when the address changes
		setPrevAddress(address);

		// Switch the account logic
		switchAccount();
	}, [address]);

	const logoutPreviousAccount = async () => {
		try {
			// Add your logout logic here
			// For example, call a function like Logout to log out the previous account
			await Logout();

			// You may need additional logic based on your application requirements
		} catch (error) {
			console.error("Error logging out previous account:", error);
		}
	};
	useEffect(() => {
		if (isConnected) {
			// Show a message when the wallet is connected
			setShowMessage(true);

			// Call the registerOrLoginUser function when the wallet is connected
			const user = {
				walletAddress: address,
			};
			connectAndLogin(user);
		} else {
			// Hide the message when the wallet is disconnected
			setShowMessage(false);
		}
	}, [isConnected, address]);

	const getMyNFTs = async () => {
		try {
			const nfts = await readContract({
				abi: NFTAbis,
				address: NFTAddress,
				functionName: "tokensOfOwner",
				args: [address],
			});
			return nfts;
		} catch (error) {
			return [];
		}
	};

	const getTotalSupply = async () => {
		try {
			const supply = await readContract({
				abi: NFTAbis,
				address: NFTAddress,
				functionName: "totalSupply",
			});
			setTotalSupply(Number(supply));
		} catch (error) {
			return [];
		}
	};

	const isNFTListing = async (id) => {
		try {
			const url = `https://api.reservoir.tools/orders/asks/v5?token=0xbe7cf276b6e25cc677cb373121efe3a943e1bec7%3A${id}`;

			const headers = {
				"x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
			};
			const res = await axios.get(url, { headers });
			if (res.data.orders.length === 0) {
				return false;
			} else {
				return true;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	};
	const addNftsInDb = async (nfts) => {
		try {
			// Call the registerOrLoginUser function
			const result = await addTotalNfts(nfts);

			if (result.success) {
				// Display a success message
				setMessage(result.message);
				setIsError(false);
			} else {
				// Display an error message
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error connecting and logging in:", error);
			// Display an error message
			setMessage("Error connecting and logging in");
			setIsError(true);
		}
	};
	const getNFTsDetails = async () => {
		const ids = await getMyNFTs();
		const data = {};
		try {
			setLoading(true);
			// if (Object.keys(userNFTs).length === 0) {
			for (let i = 0; i < ids.length; i++) {
				// --- uncomment when we need real data ---

				// const nft = await readContract({
				// 	abi: NFTAbis,
				// 	address: NFTAddress,
				// 	functionName: "tokenURI",
				// 	args: [ids[i]],
				// });
				// const res = await axios.get(nft);
				// const nftData = res.data;

				// --- uncomment when we need real data ---
				const status = await isNFTListing(ids[i]);
				data[ids[i]] = {
					id: ids[i],
					name: "ORIGO Origins NFT",
					description:
						"Degen Distillery is co-creating the world’s first spirits brand powered by next-gen technology.",
					image: "https://degen-distillery.s3.amazonaws.com/public/hidden.gif",
					external_url: "https://degendistillery.com/",
					listed: status,
				};
				// if (status) {
				// 	// If NFT is listed, remove it from the database
				// 	const nft = {
				// 		nft_id: Number(ids[i]),
				// 	};
				// 	await removeFromStake(nft);
				// }
			}

			setUserNFTs(data);

			const nftsdata = {
				total_nfts: Object.keys(data).length,
			};
			addNftsInDb(nftsdata);
			return data;
			// } else {
			//   return userNFTs;
			// }
		} catch (error) {
			console.log(error);
			return {};
		}
	};

	const getNFTDetails = async (id) => {
		try {
			// const nft = await readContract({
			// 	abi: NFTAbis,
			// 	address: NFTAddress,
			// 	functionName: "tokenURI",
			// 	args: [Number(id)],
			// });
			// console.log(nft);
			const status = await isNFTListing(Number(id));
			const nft = {
				name: "ORIGO Origins NFT",
				description:
					"Degen Distillery is co-creating the world’s first spirits brand powered by next-gen technology.",
				image: "https://degen-distillery.s3.amazonaws.com/public/hidden.gif",
				external_url: "https://degendistillery.com/",
				listed: status,
			};
			return nft;
		} catch (error) {
			return [];
		}
	};
	const createTokenBoundAccount = async (id) => {
		const walletClient = createWalletClient({
			chain: mainnet,
			account: address,
			transport: window.ethereum ? custom(window.ethereum) : http(),
		});
		const tokenboundClient = new TokenboundClient({
			walletClient,
			chainId: mainnet.id,
		});
		const tokenboundAccount = await tokenboundClient.createAccount({
			tokenContract: NFTAddress,
			tokenId: id.toString(),
		});
		return tokenboundAccount.account;
	};
	const getTokenBoundAccount = async (id) => {
		const walletClient = createWalletClient({
			chain: mainnet,
			account: address,
			transport: window.ethereum ? custom(window.ethereum) : http(),
		});
		const tokenboundClient = new TokenboundClient({
			walletClient,
			chainId: mainnet.id,
		});

		const tokenboundAccount = await tokenboundClient.getAccount({
			tokenContract: NFTAddress,
			tokenId: id.toString(),
		});
		console.log("TOKENBOUND ACCOUNT", tokenboundAccount);
		const isAccountDeployed = await tokenboundClient.checkAccountDeployment({
			accountAddress: tokenboundAccount,
		});

		console.log("DEPLOYED?", isAccountDeployed);
		return tokenboundAccount;
	};
	const messageSign = async (message) => {
		try {
			const sign = await signMessage({ message });
			return sign;
		} catch (error) {
			return { error: false, code: 4001 };
		}
	};
	const key = address || "defaultKey";

	return (
		<Context.Provider
			key={key}
			value={{
				connectWallet,
				disconnectWallet,
				address,
				isConnected,
				getNFTsDetails,
				getNFTDetails,
				createTokenBoundAccount,
				getTokenBoundAccount,
				messageSign,
				showMessage,
				setShowMessage,
				message,
				setMessage,
				isError,
				setIsError,
				userPoints,
				getPoints,
				userNFTs,
				setUserPoints,
				totalSupply,
			}}
		>
			{children}
		</Context.Provider>
	);
};
