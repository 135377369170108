import { Route, Routes } from "react-router-dom";
import "./App.css";
import SideMenu from "./component/SideMenu";
import Home from "./pages/home";
import Nft_detail from "./pages/nft_detail/nft_detail";
import Staking from "./pages/staking/Staking";
import Leaderborads from "./pages/leaderboards/Leaderboards";
import { Image } from "./assests/images";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { mainnet } from "viem/chains";
import { ContextProvider } from "./context/Context";
import { ApiContextProvider } from "./context/dappContext";

import { walletConnectProvider, EIP6963Connector } from "@web3modal/wagmi";
import { publicProvider } from "wagmi/providers/public";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";
// import { useAccount } from "wagmi";

function App() {
	const [show, setShow] = useState(true);
	const projectId = process.env.REACT_APP_ProjectId;

	const metadata = {
		name: "Degen Distillery",
		description: "Degen Distillery",
		url: "https://web3modal.com",
		icons: ["https://avatars.githubusercontent.com/u/37784886"],
	};

	const { chains, publicClient } = configureChains(
		[mainnet],
		[
			infuraProvider({
				apiKey: process.env.REACT_APP_INFURA_API_KEY,
			}),
		]
	);

	const wagmiConfig = createConfig({
		autoConnect: true,
		connectors: [
			new WalletConnectConnector({
				chains,
				options: { projectId, showQrModal: false, metadata },
			}),
			new EIP6963Connector({ chains }),
			new InjectedConnector({ chains, options: { shimDisconnect: true } }),
			new CoinbaseWalletConnector({
				chains,
				options: { appName: metadata.name },
			}),
		],
		publicClient,
	});
	// console.log(wagmiConfig);
	createWeb3Modal({ wagmiConfig, projectId, chains });

	return (
		<>
			<WagmiConfig config={wagmiConfig}>
				<ApiContextProvider>
					<ContextProvider>
						<div className="app">
							<SideMenu show={show} setShow={setShow} />
							<div className="main_container">
								<div className="content-container">
									<div className="header">
										<div className="hamburger" onClick={() => setShow(!show)}>
											<GiHamburgerMenu className="hamburger-icon" />
										</div>
										<div className="header-brand">
											<img src={Image.logo} alt="logo" />
										</div>
									</div>

									<Routes>
										<Route path="/" element={<Home />} />
										<Route path="/staking" element={<Staking />} />
										<Route path="/leaderboards" element={<Leaderborads />} />
										<Route path="/nft-detail/:id" element={<Nft_detail />} />
										{/* <Route path="/mint" element={<CarDetailSkeleton />} /> */}
									</Routes>
								</div>
							</div>
						</div>
					</ContextProvider>
				</ApiContextProvider>
			</WagmiConfig>
		</>
	);
}

export default App;
