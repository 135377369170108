import React from 'react'
import "./index.css"
const CustomeCheckBox = ({onChange,checked }) => {
  return (
    <label>
    <input type="checkbox" onChange={onChange} checked={checked} />
    <span class="custom-checkbox"></span>
   
  </label>
  )
}

export default CustomeCheckBox
