import React from "react";
import "./index.css";
const Loader = () => {
  return (
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      
    </div>
  );
};

export default Loader;
