import React from "react";
import "./index.css";
import { Image } from "../../assests/images";
const PageHeader = ({ headerText }) => {
  return (
    <div className="page_header_container">
      <div className="text_container">
        <h2 className="page_header">{headerText}</h2>
        <img src={Image.HeaderTop} className="sup_img" />
      </div>
    </div>
  );
};

export default PageHeader;
