import React, { useContext, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { MdOutlineDiamond } from "react-icons/md";

import Account_Tap from "../CreateAccount_Btn/AccountBtn";
import { Context } from "../../context/Context";
import { pointContext } from "../../context/dappContext";

const Card = ({ nft, stakeData }) => {
	const isNftInStake =
		stakeData &&
		stakeData?.some(
			(stake) => stake.nft_id === Number(nft.id) && stake.isStaked
		);
	const matchedStake = stakeData?.find(
		(stake) => stake.nft_id === parseInt(nft.id)
	);

	return (
		<div className="card">
			{/* <AddressTap walletAddress={walletAddress} /> */}
			<Account_Tap matchedStake={matchedStake} nft={nft} id={nft.id} />
			<Link to={`/nft-detail/${nft.id}`}>
				<img src={nft.image} alt="nft" className="card_img" />
			</Link>
			<div className="card_body">
				<div className="card_content">
					<h4>{nft.name}</h4>
					<span>#{Number(nft?.id)}</span>
				</div>
				{/* {nft.nft.listed ? (
					<button className="card_btn white">listed</button>
				) : (
					<button className="card_btn yellow">staked</button>
				)} */}
				{nft.listed ? (
					<button className="card_btn white">listed</button>
				) : isNftInStake ? (
					<button className="card_btn yellow">staked</button>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default Card;
