import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
export const pointContext = createContext();
export const ApiContextProvider = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const [profileData, setProfileData] = useState({});
	const [AllPoints, setAllPoints] = useState("");
	const [message, setMessage] = useState("");
	const [isError, setIsError] = useState(false);
	const [allStakedNft, setAllStakedNFt] = useState({});
	const { address, isConnected } = useAccount();
	const [userLogin, setUserLogin] = useState(false);
	async function fetchProfileData() {
		try {
			const profile = await getProfileData();
			if (profile.success) {
				setProfileData(profile.data);
			}
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	}

	const allPoints = async () => {
		try {
			const result = await getAllPoints();
			if (result.success) {
				setAllPoints(result.points);
				setMessage(result.message);
				setIsError(false);
			} else {
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setMessage("Error fetching data");
			setIsError(true);
		} finally {
			// setLoading(false);
		}
	};
	const allStakedNftsData = async () => {
		try {
			const result = await getAllStakedNfts();
			if (result.success) {
				setAllStakedNFt(result.data);
				setMessage(result.message);
				setIsError(false);
			} else {
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setMessage("Error fetching data");
			setIsError(true);
		} finally {
			// setLoading(false);
		}
	};
	function fetchAllData() {
		setLoading(true);
		Promise.all([fetchProfileData(), allPoints(), allStakedNftsData()])
			.then(() => {
				setIsError(false);
			})
			.catch(() => {
				setIsError(true);
			})
			.finally(() => {
				// setLoading(false); // Set loading to false after both fetch operations are complete
			});
		setLoading(false);
	}

	///------
	useEffect(() => {
		if (userLogin) {
			fetchAllData();
			getProfileData();
		}
	}, [userLogin]);

	const loginUser = async (user) => {
		try {
			const { data } = await axios.post(`/api/user-wallet-address/login`, user);
			const userData = data.user;

			sessionStorage.setItem("userData", JSON.stringify(userData));
			return userData;
		} catch (err) {
			return err.response.data.message;
		}
	};
	const registerUser = async (user) => {
		try {
			const { data } = await axios.post(
				`/api/user-wallet-address/register`,
				user
			);
			const userData = data.user;

			sessionStorage.setItem("userData", JSON.stringify(userData));
			return userData;
		} catch (err) {
			return err.response.data.message;
		}
	};
	const registerOrLoginUser = async (user) => {
		try {
			const { data } = await axios.post(
				`/api/user-wallet-address/register-or-login`,
				user
			);
			const userData = data.user;
			setUserLogin(true);
			return {
				success: true,
				message: "User login successful!",
				data: userData,
			};
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};
	const Logout = async () => {
		try {
			const { data } = await axios.get(`/api/user-wallet-address/logout`);
			setUserLogin(false);
			return data;
		} catch (err) {
			return err.response.data.message;
		}
	};
	const getAllStakedNfts = async () => {
		try {
			const { data } = await axios.get(`/api/leaderboard/all-stake-nfts`);

			return {
				success: true,
				message: "All Stakes",
				data: {
					totalStakedNftsCount: data.totalStakedNftsCount,
					totalStakedNftsPoints: data.totalStakedNftsPoints,
				},
			};
		} catch (err) {
			return {
				success: false,
				message: err.response.data.message,
			};
		}
	};
	const getAllUserbyAdmin = async () => {
		try {
			const { data } = await axios.get(
				`/api/user-wallet-address/get-all-user/admin`
			);
			return data;
		} catch (err) {
			return err.response.data.message;
		}
	};
	const AddStake = async (stakes, signature) => {
		try {
			const dataToSend = {
				stakes: stakes.map((stake) => ({
					nft_id: stake.nft_id,
					nft_name: stake.nft_name,
				})),
				signature: signature,
			};

			const { data } = await axios.post(
				`/api/leaderboard/add-stake`,
				dataToSend
			);
			return {
				success: true,
				message: "Stake added successfully!",
				data: data,
			};
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};
	// change end point
	const bonusPoints = async (bonusPoint, signature) => {
		try {
			const dataToSend = {
				nft_id: bonusPoint.nft_id,
				signature: signature,
			};
			const { data } = await axios.put(
				`/api/leaderboard/claim-bonus`,
				dataToSend
			);
			return data;
		} catch (err) {
			return err.response.data.message;
		}
	};
	const addTotalNfts = async (total_nfts) => {
		try {
			const { data } = await axios.put(
				`/api/leaderboard/add-total-nfts`,
				total_nfts
			);
			return {
				success: true,
				message: "Total NFTs added successfully!",
				user: data.getProfileDetail,
			};
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};
	const getProfileData = async () => {
		try {
			const { data } = await axios.get(`/api/leaderboard/get-profile-point`);
			return {
				success: true,
				message: "Profile data",
				data: data.profileDetail,
			};
		} catch (err) {
			console.log("error", err.response.data.message);
			// window.location.reload();
			return { success: false, message: err.response.data.message };
		}
	};

	const topTenPoints = async () => {
		try {
			const { data } = await axios.get(`/api/leaderboard/top-10-points/sorted`);
			return { success: true, message: data.message, data: data.top10Points };
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};
	const tokenBoundAccount = async (boundAccount) => {
		try {
			const { data } = await axios.post(
				`/api/leaderboard/token-bound-account`,
				boundAccount
			);
			return { success: true, message: data.message, data: data.user };
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};
	const getAllPoints = async () => {
		try {
			const { data } = await axios.get(`/api/leaderboard/get-all-points/admin`);
			return {
				success: true,
				message: data.message,
				points: data.TotaluserPoints,
			};
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};
	const removeFromStake = async (nft) => {
		try {
			const { data } = await axios.delete(`/api/leaderboard/remove-stake`, {
				data: nft,
			});

			return {
				success: true,
				message: data.message,
				points: data.getProfileDetail,
			};
		} catch (err) {
			return { success: false, message: err.response.data.message };
		}
	};

	return (
		<pointContext.Provider
			value={{
				loginUser,
				registerUser,
				registerOrLoginUser,
				Logout,
				getAllUserbyAdmin,
				AddStake,
				getProfileData,
				topTenPoints,
				tokenBoundAccount,
				bonusPoints,
				addTotalNfts,
				getAllPoints,
				getAllStakedNfts,
				removeFromStake,
				profileData,
				setProfileData,
				loading,
				setLoading,
				AllPoints,
				setAllPoints,
				allStakedNft,
				setAllStakedNFt,
				fetchProfileData,
				fetchAllData,
				allPoints,
				allStakedNftsData,
				userLogin,
			}}
		>
			{children}
		</pointContext.Provider>
	);
};
