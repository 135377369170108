import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import PageHeader from "../../component/PagerHeader";
import Leaderboard from "../../component/leaderboard/Leaderboard";
import { pointContext } from "../../context/dappContext";
import { Context } from "../../context/Context";
import LeaderSkeleton from "../../component/LeaderboardSkeleton";
import Alert from "../../component/alert/Alert";
const Leaderborads = () => {
	const { topTenPoints, getProfileData } = useContext(pointContext);
	const { address, isConnected } = useContext(Context);
	const [message, setMessage] = useState("");
	const [isError, setIsError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [profileData, setProfileData] = useState({});
	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await topTenPoints();
			if (result.success) {
				setLeaderboardData(result.data);
				setMessage(result.message);
				setIsError(false);
			} else {
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setMessage("Error fetching data");
			setIsError(true);
		} finally {
			setLoading(false);
		}
	};
	const fetchProfileData = async () => {
		try {
			setLoading(true);
			const result = await getProfileData();
			if (result.success) {
				setProfileData(result.data);
				setMessage(result.message);
				setIsError(false);
			} else {
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setMessage("Error fetching data");
			setIsError(true);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		if (isConnected) {
			fetchProfileData();
		}
		fetchData();
	}, [isConnected]);

	const skeletonCount = Math.floor(window.innerHeight / 100);
	const isInTop10 = isConnected
		? leaderboardData &&
		  profileData &&
		  leaderboardData.some((data) => data._id === profileData._id)
		: false;

	return (
		<div className="leaderboard_container">
			<PageHeader headerText={"leaderboards"} />
			{isError && (
				<div>
					<Alert message={message} />
				</div>
			)}
			<div className="leaderboard_header">
				<div className="position">
					<span>position</span>
				</div>
				<div className="rank">
					<span>rank</span>
				</div>
				<div className="wallet_address">
					<span>holder</span>
				</div>
				<div className="points">
					<span>points</span>
				</div>
			</div>
			<div className="board_container">
				{loading && (
					<div>
						{Array.from({ length: skeletonCount }).map((_, index) => (
							<LeaderSkeleton key={index} />
						))}
					</div>
				)}

				<>
					{leaderboardData &&
						leaderboardData.map((data, index) => (
							<Leaderboard
								key={index}
								data={data}
								index={index + 1}
								isHighlighted={isInTop10 && data._id === profileData._id}
							/>
						))}
				</>
			</div>
			{isConnected &&
				address &&
				Object.keys(profileData).length > 0 &&
				!isInTop10 && (
					<>
						<div className="dots">
							<span></span>
							<span></span>
							<span></span>
						</div>
						<div>
							<Leaderboard current={"current"} data={profileData} />
						</div>
					</>
				)}
		</div>
	);
};

export default Leaderborads;
