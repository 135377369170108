import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import PageHeader from "../../component/PagerHeader";
import { Image } from "../../assests/images";
import { IoIosAdd } from "react-icons/io";
import { IoDiamondOutline } from "react-icons/io5";
import { PiCubeThin } from "react-icons/pi";
import Alert from "../../component/alert/Alert";
import Trait from "../../component/Trait";
import { Context } from "../../context/Context";
import { useParams } from "react-router-dom";
import { pointContext } from "../../context/dappContext";
import Loader from "../../component/loader";
import Skeleton from "react-loading-skeleton";
import CarDetailSkeleton from "../../component/cardDetailSkeleton";
import { FaRegCopy } from "react-icons/fa6";

const Nft_detail = () => {
	const {
		isConnected,
		getNFTDetails,
		createTokenBoundAccount,
		showMessage,
		setShowMessage,
		message,
		setMessage,
		isError,
		setIsError,
	} = useContext(Context);
	const { tokenBoundAccount, getProfileData } = useContext(pointContext);
	const [profileData, setProfileData] = useState({});
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const fetchProfileData = async () => {
		try {
			setLoading(true);
			const result = await getProfileData();
			if (result.success) {
				setProfileData(result.data);
				setMessage(result.message);
				setIsError(false);
				setButtonDisabled(true);
			} else {
				setMessage(result.message);
				setIsError(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setMessage("Error fetching data");
			setIsError(true);
		} finally {
			setLoading(false);
		}
	};
	const { id } = useParams();
	const [nft, setNft] = useState({});
	const [loading, setLoading] = useState(true);
	const [account, setAccount] = useState(null);
	const [loadingProfileData, setLoadingProfileData] = useState(true);
	const [loadingNFT, setLoadingNFT] = useState(true);
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		async function fetchData() {
			try {
				const [profileResult, nftData] = await Promise.all([
					getProfileData(),
					getNFTDetails(id),
				]);

				if (profileResult.success) {
					setProfileData(profileResult.data);
					setMessage(profileResult.message);
					setIsError(false);
					setButtonDisabled(true);
				} else {
					setMessage(profileResult.message);
					setIsError(true);
				}

				setNft(nftData);
			} catch (error) {
				console.error("Error fetching data:", error);
				setMessage("Error fetching data");
				setIsError(true);
			} finally {
				setLoadingProfileData(false);
				setLoadingNFT(false);
			}
		}

		fetchData();
	}, [id]);
	const boundAccount = {
		nft_id: 150,
		bound_account_address: "0x5154B93749E14CEa08B1090489e64BAF54831D9a",
	};
	useEffect(() => {
		if (isConnected) {
			fetchProfileData();
		}
	}, [isConnected]);

	async function getAccount(id) {
		try {
			const _account = await createTokenBoundAccount(id);
			if (_account) {
				const boundAccount = {
					nft_id: Number(id),
					nft_name: nft?.name,
					bound_account_address: _account,
				};
				tokenBoundAccount(boundAccount);
				window.location.reload();
			}
			setAccount(_account);
		} catch (error) {
			console.log(error);
		}
	}
	const matchedStake = profileData?.stake?.find(
		(stake) => stake.nft_id === parseInt(id)
	);
	const address = matchedStake?.token_bound_account?.address;

	const copyOnClipboard = (account) => {
		navigator.clipboard.writeText(account);
		setIsCopied(true);

		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	};

	return (
		<>
			{loadingProfileData || loadingNFT ? (
				<>
					<div>
						<CarDetailSkeleton />
					</div>
				</>
			) : (
				<>
					<div className="nft_detail_container">
						<PageHeader headerText={"my nfts"} />
						<div className="nft_details">
							<div className="img_container">
								<img src={nft.image} alt="art" />
							</div>
							<div className="content_container">
								<div className="detail_header_container">
									<div className="detail_header">
										<a
											href={`https://opensea.io/assets/ethereum/0xbe7cf276b6e25cc677cb373121efe3a943e1bec7/${id}`}
											target="_blank"
										>
											<h4>View on OpenSea</h4>
										</a>
										{!matchedStake?.isStaked ? (
											""
										) : nft.listed ? (
											<button className="status white">listed</button>
										) : (
											<button className="status yellow">staked</button>
										)}
										{/* {matchedStake?.isStaked ? (
                      <span>staked</span>
                    ) : (
                      nft.listed && <span>listed</span>
                    )} */}
									</div>
									<div className="title">
										<h3>{nft.name}</h3>
										<span>#{id}</span>
									</div>
								</div>
								{/* <p>{account}</p> */}
								{matchedStake?.token_bound_account?.bound_account &&
								matchedStake?.token_bound_account?.address ? (
									<>
										<Alert
											message={`Your ORIGO’s Token Bound Account address.`}
										/>
										<div className="create_section">
											<div className="token_section">
												<p>{address}</p>
											</div>
											<div
												className="copy_points"
												onClick={() => {
													copyOnClipboard(address);
												}}
											>
												<span className={isCopied ? "copied" : ""}>
													<FaRegCopy className="icon-styles" />
												</span>
												{isCopied && (
													<div className="popup">Address copied!</div>
												)}
											</div>
										</div>
									</>
								) : (
									<>
										<Alert
											message={`Setup a Token Bound Account and receive points!`}
										/>
										<div className="create_section">
											<div
												className="token_section"
												onClick={() => (account ? null : getAccount(id))}
											>
												<p>create a token bound account</p>
											</div>
											<div
												className="points"
												onClick={() => {
													tokenBoundAccount(boundAccount);
												}}
											>
												<span>+</span>
												<span>400</span>
												<span>
													<img
														src={Image.createDiamond}
														alt="create"
														className="point_img"
													/>
												</span>
											</div>
										</div>
									</>
								)}

								<div className="trait_container">
									<h4>traits</h4>

									<div className="rarity_container">
										<Trait
											active={"active"}
											disc={"points"}
											//   points={profileData?.points?.value}
											points={
												matchedStake?.nft_points?.value
													? matchedStake?.nft_points?.value
													: 0
											}
										/>
										<Trait />
										<Trait />
										<Trait />
										<Trait />
										<Trait />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Nft_detail;
