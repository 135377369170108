import React from 'react'
import Skeleton from 'react-loading-skeleton'
import "./index.css"

const LeaderSkeleton = () => {
  return (
    <div>
      <Skeleton className='leader_board_skeleton'/>
    </div>
  )
}

export default LeaderSkeleton
